// MainContent.js
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import CreateProof from './CreateProof';
import VerifyProof from './VerifyProof';

const MainContent = ({ user }) => {
  const [activeTab, setActiveTab] = useState('create');

  if (!user) {
    return <Navigate to="/signin" />;
  }

  return (
    <div>
      <h1 className="text-4xl font-bold text-center mb-8 pb-12 pt-12">Create proof for your digital assets</h1>
      <div className="mb-4 flex justify-center">
        <button
          className={`px-6 py-2 ${activeTab === 'create' ? 'bg-gray-800' : 'bg-gray-700'} rounded-tl-lg rounded-tr-lg mr-2 transition-colors duration-200 hover:bg-gray-600`}
          onClick={() => setActiveTab('create')}
        >
          Create Proof
        </button>
        <button
          className={`px-6 py-2 ${activeTab === 'verify' ? 'bg-gray-800' : 'bg-gray-700'} rounded-tl-lg rounded-tr-lg transition-colors duration-200 hover:bg-gray-600`}
          onClick={() => setActiveTab('verify')}
        >
          Verify Proof
        </button>
      </div>
      {activeTab === 'create' ? <CreateProof /> : <VerifyProof />}
    </div>
  );
};

export default MainContent;
