import React, { useState, useRef } from 'react';
//import { verifyProof } from '../utils/proofHandler';

import { signerservicesdk } from '@truthstampai/signer-service-sdk'; // Import the createProof function

const VerifyProof = () => {
  const [file, setFile] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setErrorMessage(''); // Clear error message when a file is selected
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleVerifyProof = async () => {
    if (!file) {
      setErrorMessage('Please select a proof file first.');
      return;
    }

    setErrorMessage('');
    setIsLoading(true);

    try {
      const proofData = JSON.parse(await file.text());
      setVerificationStatus('Starting verification process...');
      const API_KEY = process.env.REACT_APP_TRUTHSTAMP_API_KEY;
      signerservicesdk.init(API_KEY);
      const proofResult = await signerservicesdk.verifyProof(proofData);
      setVerificationStatus(JSON.stringify(proofResult, null, 2));
    } catch (error) {
      console.error('Error:', error);
      setVerificationStatus('An error occurred while verifying the proof.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Verify Proof</h2>
      <div
        className={`border-2 border-dashed ${isDragging ? 'border-blue-500' : 'border-gray-500'} rounded-lg p-8 text-center cursor-pointer mb-4`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current.click()}
      >
        <p>{file ? `File selected: ${file.name}` : 'Drag and drop your JSON proof file here, or click to select'}</p>
        <input
          type="file"
          onChange={handleFileChange}
          className="hidden"
          ref={fileInputRef}
          accept="application/json"
        />
      </div>
      {errorMessage && (
        <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
      )}
      <button
        onClick={handleVerifyProof}
        className="px-4 py-2 bg-black rounded hover:bg-gray-700"
        disabled={isLoading}
      >
        {isLoading ? 'Verifying...' : 'Verify Proof'}
      </button>
      {isLoading && (
        <div className="flex justify-center mt-4">
          <div className="loader-dots"></div>
        </div>
      )}
      {verificationStatus && (
        <div className="mt-4 bg-gray-700 rounded p-4 max-h-60 overflow-y-auto">
          <pre className="whitespace-pre-wrap break-words">
            <code>{verificationStatus}</code>
          </pre>
        </div>
      )}
      <style jsx>{`
        .loader-dots {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .loader-dots::before, .loader-dots::after {
          content: '';
          display: inline-block;
          width: 1rem;
          height: 1rem;
          margin: 0 0.25rem;
          background-color: #3498db;
          border-radius: 50%;
          animation: bounce 0.6s infinite alternate;
        }
        .loader-dots::after {
          animation-delay: 0.3s;
        }
        @keyframes bounce {
          from {
            transform: translateY(0);
          }
          to {
            transform: translateY(-1rem);
          }
        }
      `}</style>
    </div>
  );
};

export default VerifyProof;
