import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import Header from './components/Header';
import Footer from './components/Footer';
import MainContent from './components/MainContent';
import SignIn from './components/SignIn';

function App() {
  const [currentUser, setCurrentUser] = useState(null); // State to store signed-in user details

  // Retrieve user from localStorage when the app loads
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser)); // Parse and set user from localStorage
    }
  }, []);

  // Handle sign-in and save user in both state and localStorage
  const handleSignIn = (userData) => {
    setCurrentUser(userData);    
    localStorage.setItem('user', JSON.stringify(userData)); // Store user in localStorage
    console.log('Signed in user:', userData);
  };

  // Handle sign-out and clear user data from both state and localStorage
  const handleSignOut = () => {
    setCurrentUser(null);
    localStorage.removeItem('user'); // Clear user from localStorage
    window.location.href = '/'; // Redirect to sign-in page after logout
  };

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return (
    <GoogleOAuthProvider clientId={ clientId }> {/* Ensure GoogleOAuthProvider wraps your app */}
      <Router>
        <div className="bg-gray-900 text-gray-100 min-h-screen flex flex-col">
          <Header user={currentUser} onSignOut={handleSignOut} />

          {/* Add padding to main to avoid overlap */}
          <main className="flex-grow container mx-auto px-4 pt-24 pb-16 max-w-3xl mx-auto"> {/* pt-20 adds padding to prevent overlap */}
           
            <Routes>
              <Route path="/signin" element={!currentUser ? <SignIn onSignIn={handleSignIn} /> : <Navigate to="/" />} />
              <Route path="/" element={currentUser ? <MainContent user={currentUser} /> : <Navigate to="/signin" />} />                          
            </Routes>
          </main>

          <Footer />
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
