// SignIn.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // Correct default import
//import GitHubOAuth from '../GitHubOAuth';

const SignIn = ({ onSignIn }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

   useEffect(() => {
    // Get user data from the query string (sent from the backend)
    const urlParams = new URLSearchParams(window.location.search);
    const userDataString = urlParams.get('user');
    console.log(userDataString);
    if (userDataString != null) {
      // Parse the user data
      const userData = JSON.parse(decodeURIComponent(userDataString));
      console.log('GitHub User Data:', userData);

      // Pass the user info to the parent component (or update state)
       onSignIn({
        name: userData.name,
        email: userData.email,
        picture: userData.avatar_url,
      });
 
      // Redirect to home page after successful sign-in
      navigate('/');
    } else {
      console.log('ss');
    }
  }, [navigate, onSignIn]); 

  const handleGoogleSuccess = (credentialResponse) => {
    try {
      // Decode the JWT to get user information
      const decoded = jwtDecode(credentialResponse.credential);
      console.log('Decoded Google User:', decoded);

      // Pass user info to parent component
      onSignIn({
        name: decoded.name,
        email: decoded.email,
        picture: decoded.picture,
      });

      // Redirect to home page after successful login
      navigate('/');
    } catch (error) {
      console.error('Error decoding Google JWT:', error);
    }
  };

  const handleGitHubLogin = () => {
    const clientId = 'Ov23li5lm3kw9CJYR38K';
    const redirectUri = 'https://insgb0039c.execute-api.eu-central-1.amazonaws.com/recipient/gitAccess';
    const githubLoginUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user`;

    // Redirect to GitHub OAuth login
    window.location.href = githubLoginUrl;
  };
  
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-center text-white">Sign In</h2>
        <div className="space-y-4">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={() => console.log('Google Sign In Failed')}
            className="w-full"
          />
          <button
          onClick={handleGitHubLogin}
          className="w-full bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600 transition duration-200"
        >
          Sign in with GitHub
        </button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
