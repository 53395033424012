import React, { useState, useRef } from 'react';
//import { createProof } from '../utils/proofHandler'; // Import the createProof function
import { signerservicesdk } from '@truthstampai/signer-service-sdk'; // Import the createProof function
import { ClipboardIcon } from '@heroicons/react/outline'; // Import ClipboardIcon

const CreateProof = () => {
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState('');
  const [proofResult, setProofResult] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [copied, setCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleDownloadProof = () => {
    if (!description.trim()) {
      alert('Description is required to generate the file name.');
      return;
    }

    const firstWord = description.split(' ')[0] || 'proof';
    const fileName = `${firstWord}.json`;
    const file = new Blob([proofResult], { type: 'application/json' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleCreateProof = async () => {
    let error = '';

    if (!description.trim()) {
      error += 'Description is required. ';
    }

    if (!file) {
      error += 'File is required.';
    }

    if (error) {
      setErrorMessage(error);
      return;
    }

    setErrorMessage('');
    setIsLoading(true);

    try {
      const API_KEY = process.env.REACT_APP_TRUTHSTAMP_API_KEY;
      signerservicesdk.init(API_KEY);
      const proof = await signerservicesdk.createProof(file, description);
      setProofResult(JSON.stringify(proof, null, 2));
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred while creating the proof.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyProof = () => {
    navigator.clipboard.writeText(proofResult)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => {
        console.error('Failed to copy:', err);
      });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Create Proof</h2>
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className={`w-full h-32 bg-gray-700 rounded p-2 mb-1 ${errorMessage ? 'border border-red-500' : ''}`}
        placeholder="Describe your digital asset (required)..."
      ></textarea>
      {errorMessage && !file && (
        <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
      )}
      <div
        className={`border-2 border-dashed ${isDragging ? 'border-blue-500' : 'border-gray-500'} rounded-lg p-8 text-center cursor-pointer mb-4`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current.click()}
      >
        <p>{file ? `File selected: ${file.name}` : 'Drag and drop your file here, or click to select'}</p>
        <input
          type="file"
          onChange={handleFileChange}
          className="hidden"
          ref={fileInputRef}
        />
      </div>
      {errorMessage && file && (
        <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
      )}
      <button
        onClick={handleCreateProof}
        className="px-4 py-2 bg-black rounded hover:bg-gray-700"
        disabled={isLoading}
      >
        {isLoading ? 'Creating Proof...' : 'Create Proof'}
      </button>
      {isLoading && (
        <div className="flex justify-center mt-4">
          <div className="loader-dots"></div>
        </div>
      )}
      {proofResult && (
        <div className="mt-4 bg-gray-700 rounded p-4 relative">
          <button
            onClick={handleCopyProof}
            className={`absolute top-2 right-2 p-2 rounded ${copied ? 'bg-green-600' : 'bg-blue-500'} ${copied ? 'text-white' : 'text-white'} hover:${copied ? 'bg-green-700' : 'bg-blue-600'}`}
          >
            <ClipboardIcon className="h-6 w-6" />
          </button>
          <button
            onClick={handleDownloadProof}
            className="px-4 py-2 bg-green-600 rounded hover:bg-green-700 mr-2"
          >
            Download Proof
          </button>
          <pre className="whitespace-pre-wrap break-words mt-8">
            <code>{proofResult}</code>
          </pre>
        </div>
      )}
      <style jsx>{`
        .loader-dots {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .loader-dots::before, .loader-dots::after {
          content: '';
          display: inline-block;
          width: 1rem;
          height: 1rem;
          margin: 0 0.25rem;
          background-color: #3498db;
          border-radius: 50%;
          animation: bounce 0.6s infinite alternate;
        }
        .loader-dots::after {
          animation-delay: 0.3s;
        }
        @keyframes bounce {
          from {
            transform: translateY(0);
          }
          to {
            transform: translateY(-1rem);
          }
        }
      `}</style>
    </div>
  );
};

export default CreateProof;
