import React from 'react';

const Header = ({ user, onSignOut }) => {
  return (
    <header className="bg-opacity-30 backdrop-filter backdrop-blur-lg bg-gray-800 fixed w-full z-10">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* App Title */}
        <h1 className="text-2xl font-bold">Truthstamp</h1>
        
        {/* Display user profile picture and logout */}
        {user && (
          <div className="flex items-center space-x-4">
            {/* User profile picture */}
            <img 
              src={user.picture} 
              alt="User profile" 
              className="w-10 h-10 rounded-full border-2 border-gray-400"
            />
            {/* Logout button */}
            <button 
              onClick={onSignOut} 
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200">
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
